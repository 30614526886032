import {
    S3Attachment,
    DocumentTranslationWidget as DocWidget,
    WidgetContext,
    createWidgetContext
} from "@amzn/document-analysis-widget-assets";
import React, { useEffect, useState } from "react";
import { AlchemyAPI } from "./AlchemyApi";
import { logger } from "src/logger";
import {
    PageSection,
    PageSectionPadding,
    useParagonContext,
    useParagonContextConsumerEl
} from "@amzn/paragon-ui-react";
import { paragonWidgetDataContext } from "@amzn/paragon-context/lib/ParagonWidgetDataContext";
import { createDocumentTranslationConsumer } from "@amzn/paragon-context/lib/DocumentTranslationContext";
import { ClickMetrics } from "src/metrics";
import { TranslatableDocument } from "@amzn/paragon-context/lib/DocumentTranslationContext/models/TranslatableDocument";
import { ImportedDocuments } from "src/@types/importedDocuments";
import { parseImportedDocuments } from "../utils";
export const DocumentTranslationWidget = () => {
    const [documentList, setDocumentList] = useState<S3Attachment[]>([]);
    const [importedDocuments, setImportedDocuments] = useState<TranslatableDocument[]>([]);
    const [widgetContext, setWidgetContext] = useState<WidgetContext>(createWidgetContext());

    // Paragon context
    const el = useParagonContextConsumerEl();

    // Methods widget can call [proxyGet, proxyPost, proxyPut]
    const { methods: widgetMethods } = useParagonContext(el, paragonWidgetDataContext);

    // When paragon context changes, run function to add documents
    useEffect(() => {
        if (!el) return;
        const unsubscribe = createDocumentTranslationConsumer(el, setImportedDocuments);
        return () => unsubscribe();
    }, [el]);

    // When documents are added from context parse them and send them to the widget
    useEffect(() => {
        setDocumentList(parseImportedDocuments(importedDocuments as ImportedDocuments[]));
    }, [importedDocuments]);

    return (
        <>
            {documentList.length > 0 && (
                <PageSection title={"Document Translation"} widgetId="document-translation-widget">
                    <PageSectionPadding>
                        <DocWidget
                            documentList={documentList}
                            isOverlayDisplay={true}
                            alchemyApiImpl={new AlchemyAPI(widgetMethods)}
                            clientId={"Paragon"}
                            domainId={"CaseView"}
                            clientMetadata={[]}
                            loggerImpl={logger}
                            metricEmitterImpl={new ClickMetrics("DocumentTranslationWidgetClickMetrics")}
                            widgetContext={widgetContext}
                        />
                    </PageSectionPadding>
                </PageSection>
            )}
        </>
    );
};
