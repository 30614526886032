import { FileType, S3Attachment } from "@amzn/document-analysis-widget-assets";
import { ImportedDocuments } from "src/@types/importedDocuments";

export const parseImportedDocuments = (documents: ImportedDocuments[]): S3Attachment[] => {
    const attachmentMap = new Map<string, S3Attachment>();
    const attachments: S3Attachment[] = [];
    documents.forEach((document) => {
        const extension = getExtension(document.fileName);
        if (extension) {
            //remove duplicates
            attachmentMap.set(document.fileName, {
                type: "S3",
                fileName: document.fileName,
                presignedUrl: document.downloadUrl,
                sourceContentId: `s3://${document.s3FilePath}`,
                extension: extension
            });
        }
    });
    attachmentMap.forEach((value) => {
        attachments.push(value);
    });
    return attachments;
};

export const getExtension = (fileName: string): FileType | undefined => {
    const parts = fileName.split(".");
    const extension = parts[parts.length - 1].toLowerCase();
    switch (extension) {
        case "png":
            return "PNG" as FileType;
        case "pdf":
            return "PDF" as FileType;
        case "jpeg":
        case "jpg":
            return "JPG_JPEG" as FileType;
        default:
            return undefined;
    }
};
